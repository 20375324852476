import React from "react";
import { v4 as uuidv4 } from "uuid";
import { filter, map } from "lodash";
import NewsElement, { newsElementType } from "../../../commons/newsElement";
import { useTranslation } from "react-i18next";

import { VIOLATION } from "../../../../commons/data/allData";
import "./activity.css";

const Activity = ({ tabName }) => {
  const filteredItems = filter(VIOLATION, { type: tabName });
  const { t } = useTranslation("translation");

  return (
    <div className="violation__boardContainer">
      <div className="seeAllContainer">
        <span>{t("home.body.activities.seeAll")}</span>
        <img
          className="nextArrow"
          src="/images/commons/next-arrow.png"
          alt="see all"
        />
      </div>
      <div className="violations__list">
        {map(filteredItems, ({ imgSrc, name }) => {
          return (
            <NewsElement
              key={uuidv4()}
              imgSrc={imgSrc}
              newsName={name}
              type={newsElementType.violations}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Activity;
