export const NAVBAR_ITEMS = [
  { key: "home", href: "/", label: "Home" }, // this key match with common.json
  { key: "aboutUs", href: "/about-us", label: "About Us" },
  { key: "events", href: "/events", label: "Events" },
  { key: "publications", href: "/publications", label: "Publications" },
  { key: "news", href: "/news", label: "RGC News" },
  { key: "contactUs", href: "/contact-us", label: "Contact Us" },
];

export const DOCUMENTS = [
  {
    name: "IRGC MEMBERS DOCUMENTED",
    quantity: 3224,
    iconSrc: "/images/home/icon-users.png",
  },
  {
    name: "IRGC UNITS DOCUMENTED",
    quantity: 3224,
    iconSrc: "/images/home/icon-document.png",
  },
  {
    name: "ACTIVITIES DOCUMENTED",
    quantity: 3224,
    iconSrc: "/images/home/icon-calendar.png",
  },
  {
    name: "CRIMINAL BEHAVIORS DOCUMENTED",
    quantity: 3224,
    iconSrc: "/images/home/icon-act.png",
  },
];

export const BEHAVIORS = [
  {
    imgSrc: "/images/image-14.png",
    name: "Lorem ipsum dolor",
    description: "Lorem ipsum dolor sit amet, consectetuer.",
  },
  {
    imgSrc: "/images/image-15.png",
    name: "Lorem ipsum dolor",
    description: "Lorem ipsum dolor sit amet, consectetuer.",
  },
  {
    imgSrc: "/images/image-16.png",
    name: "Lorem ipsum dolor",
    description: "Lorem ipsum dolor sit amet, consectetuer.",
  },
  {
    imgSrc: "/images/image-17.png",
    name: "Lorem ipsum dolor",
    description: "Lorem ipsum dolor sit amet, consectetuer.",
  },
  {
    imgSrc: "/images/image-18.png",
    name: "Lorem ipsum dolor",
    description: "Lorem ipsum dolor sit amet, consectetuer.",
  },
];
export const ILLICIT_ACTIVITY = [
  {
    imgSrc: "/images/home/globe.png",
    name: "Suppression of Civil Society",
  },
  {
    imgSrc: "/images/home/children.png",
    name: "Children's Rights",
  },
  {
    imgSrc: "/images/home/terrorist.png",
    name: "Exporting Terrorism",
  },
  {
    imgSrc: "/images/home/medicine.png",
    name: "Drug Trafficking",
  },
];
export const VIOLATION = [
  {
    type: "Suppression of Civil Society",
    imgSrc: "/images/image-16.png",
    name: "Lorem ipsum dolor sit amet. ",
  },
  {
    type: "Children's Rights",
    imgSrc: "/images/image-19.png",
    name: "Lorem ipsum dolor sit amet. ",
  },
  {
    type: "Suppression of Civil Society",
    imgSrc: "/images/image-16.png",
    name: "Lorem ipsum dolor sit amet. ",
  },
  {
    type: "Exporting Terrorism",
    imgSrc: "/images/image-18.png",
    name: "Lorem ipsum dolor sit amet. ",
  },
  {
    type: "Suppression of Civil Society",
    imgSrc: "/images/image-16.png",
    name: "Lorem ipsum dolor sit amet. ",
  },
];

export const PUBLICATIONS = [
  {
    imgSrc: " /images/image-19.png",
    name: "Lorem ipsum dolor sit amet.",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    author: "Lorem",
    date: "Feb 22, 2024",
    active: true,
  },
  {
    imgSrc: " /images/image-19.png",
    name: "Lorem ipsum dolor sit amet.",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    author: "Lorem",
    date: "Feb 22, 2024",
  },
];

export const PUBLICATION_BUTTONS = ["Lorem ipsum", "Lorem ipsum"];

export const FOOTER_DATA = [
  {
    name: "About Us",
    navigate: "/aboutus",
  },
  {
    name: "Option 3",
    navigate: "/aboutus",
  },
  {
    name: "Option 4",
    navigate: "/aboutus",
  },
  {
    name: "Option 5",
    navigate: "/aboutus",
  },
  {
    name: "Option 6",
    navigate: "/aboutus",
  },
  {
    name: "Option 7",
    navigate: "/aboutus",
  },
];

export const SOCIAL_ICON = [
  { iconSrc: "/images/social/facebook-icon.png" },
  { iconSrc: "/images/social/youtube-icon.png" },
  { iconSrc: "/images/social/instagram-icon.png" },
  { iconSrc: "/images/social/x-icon.png" },
  { iconSrc: "/images/social/mail-icon.png" },
];

export const ICON_TAB_ALL = "/images/home/chart-tabs/icon-tab-1.png";
export const ICON_TAB_OTHER = "/images/home/chart-tabs/icon-tab-2.png";
export const ORGANISATIONS_SORT_BY_OPTIONS = ["name", "number", "role"];

export const yearlyData = [
  [20, 25, 29, 43, 51, 55, 48, 25, 28, 32, 50, 58],
  [16, 19, 21, 30, 38, 45, 48, 42, 27, 25, 32, 43],
  [15, 18, 20, 25, 32, 39, 41, 44, 32, 25, 20, 29],
  [6, 9, 13, 24, 32, 39, 41, 28, 19, 32, 41, 45],
];
export const monthlyData = [
  [40, 30, 15, 45, 32, 12, 45, 22, 41, 31, 35, 42, 30],
  [6, 10, 16, 23, 23, 29, 30, 34, 35, 5, 15, 20, 33],
  [15, 18, 20, 25, 32, 39, 41, 44, 32, 25, 20, 29, 25],
  [6, 9, 13, 24, 32, 39, 41, 28, 19, 32, 41, 45, 68],
];
export const dailyData = [
  [6, 9, 13, 24, 32, 39, 41, 28, 19, 32, 41, 45],
  [15, 18, 20, 25, 32, 39, 41, 44, 32, 25, 20, 29],
  [20, 25, 29, 43, 51, 55, 48, 25, 28, 32, 50, 58],
  [16, 19, 21, 30, 38, 45, 48, 42, 27, 25, 32, 43],
];
