import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { OrgChart as D3OrgChart } from "d3-org-chart";
import { getChartData } from "./dataCustom";
import CardItemDetail from "../orgChartCard/cardItemDetail";
import CardItemCenter from "../orgChartCard/cardItemCenter";
import { sortOrgs } from "../../commons/utils/sortOrgs";
import { find, forEach, map } from "lodash";
import * as d3 from "d3";
import { tippy } from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional for styling
import "../orgChartCard/cardItemDetail.css";
import Loading from "../commons/Loading";

let outerLoading = false;
let timeoutUpdate;
const GroupChart = ({ filterOptions, zoomOut }) => {
  const d3Container = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const { type, date, sortByOptions } = filterOptions || {};
  const finalFilterOptions = {
    ...(type ? { military_forces_type_id: type } : {}),
    date,
  };

  const getServerData = async (hasTimeout = true) => {
    setData([]);
    clearTimeout(timeoutUpdate);
    timeoutUpdate = setTimeout(
      async function () {
        if (isLoading || outerLoading) return;
        outerLoading = true;
        setIsLoading(true);
        const cData = await getChartData(finalFilterOptions);
        setData(cData);
        setIsLoading(false);
        outerLoading = false;
      },
      hasTimeout ? 0 : 0,
    );
  };

  useEffect(() => {
    getServerData();
  }, [setData, JSON.stringify(finalFilterOptions)]);
  const allData = data?.units;
  const showData = sortOrgs(allData, sortByOptions);
  const dateStr = date ? date.toISOString() : "";

  useLayoutEffect(() => {
    const svg = d3.select(d3Container.current).select("svg");
    const chart = new D3OrgChart().compact(false);
    const finalData = map(showData, (unit, index) => {
      const upperUnitId = showData?.find(
        (item) => item.name === unit.upper_unit,
      )?.id;
      return {
        ...unit,
        nodeId: unit.id,
        parentNodeId: unit.upper_unit_id || upperUnitId,
        index: index + 1,
      };
    });

    const arr = document.getElementsByClassName("chart");
    forEach(Array.from(arr), (node) => {
      node.remove();
    });

    svg
      .append("defs")
      .append("marker")
      .attr("id", "down-arrow")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", 5)
      .attr("refY", 8)
      .attr("markerWidth", 8)
      .attr("markerHeight", 8)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 5 10 L 10 0")
      .attr("fill", "#c8c9c3");
    if (d3Container.current) {
      chart
        .container(d3Container.current)
        .data(finalData)
        .nodeHeight((d) => 105)
        .nodeWidth((d) => 150)
        .childrenMargin((d) => 150)
        .siblingsMargin((d) => 40)
        .compactMarginBetween((d) => 35)
        .compactMarginPair((d) => 30)
        .neighbourMargin((a, b) => 20)
        .onExpandOrCollapse(() => {
          svg
            .selectAll(".link")
            .attr("stroke", "#c8c9c3")
            .attr("stroke-width", 2)
            .attr("marker-start", "url(#down-arrow)");
        })
        .nodeUpdate(function (d) {
          const currentlyHoveredElement = this;
          currentlyHoveredElement.addEventListener("mouseover", (target) => {});
          const org = find(showData, (org) => org.id === d.data.id);
          const template = ReactDOMServer.renderToStaticMarkup(
            <CardItemDetail data={org} />,
          );
          tippy(this, {
            placement: "right",
            arrow: true,
            content: template,
            allowHTML: true,
            hideOnClick: true,
            theme: "cardDetailToollip",
          });
        })
        .nodeContent((d) => {
          return ReactDOMServer.renderToStaticMarkup(
            <CardItemCenter data={d.data} />,
          );
        })
        .render();
      svg
        .selectAll(".link")
        .attr("stroke", "#c8c9c3")
        .attr("stroke-width", 2)
        .attr("marker-start", "url(#down-arrow)");
    }
    return () => {
      window.showPopup = null;
      window.handleClose = null;
    };
  }, [type, JSON.stringify(sortByOptions), dateStr, zoomOut, showData]);

  return (
    <div className="org-chart" ref={d3Container}>
      {(isLoading || outerLoading) && <Loading />}
    </div>
  );
};

export default GroupChart;
