import { map } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import BodyWrapper from "../commons/bodyWrapper";
import "./footer.css";
import { FOOTER_DATA, SOCIAL_ICON } from "../../commons/data/allData";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation("translation");
  const options = t("footer.options", {
    returnObjects: true,
  });

  return (
    <div className="footerWrapper bgGreenColorWhite">
      <BodyWrapper>
        <div className="footerContentContainer">
          <div className="optionsContainer">
            {map(options, (t) => {
              return (
                <a key={uuidv4()} className="socialName">
                  {t}
                </a>
              );
            })}
          </div>

          <div className="socialContainer">
            {map(SOCIAL_ICON, ({ iconSrc }) => {
              return (
                <a key={uuidv4()}>
                  <img className="socialImg" src={iconSrc} />
                </a>
              );
            })}
          </div>
          <span className="footer_separator"></span>
          <div className="footer_copyRight">
            <span className="footer__projectName">
              {t("footer.projectName")}
            </span>
            {currentYear} © {t("footer.reserved")}
          </div>
        </div>
      </BodyWrapper>
    </div>
  );
};

export default Footer;
