import React from "react";
import "./newsElement.css";

export const newsElementType = {
  behaviors: "behaviors",
  violations: "violations",
};

const NewsElement = ({ imgSrc, newsName, description, type }) => {
  const typeCN =
    type === newsElementType.violations ? "newsElement--violations" : "";

  return (
    <div className={`newsElement ${typeCN}`}>
      <img className="newsElement__image" src={imgSrc} alt="icon" />
      <span className="newsElement__name">{newsName}</span>
      {description && (
        <span className="newsElement__description">{description}</span>
      )}
    </div>
  );
};

export default NewsElement;
