import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./lineGraphModel.css";
import {
  yearlyData,
  dailyData,
  monthlyData,
} from "../../../../commons/data/allData.js";

const LineGraphModel = ({ duration, zoomOut }) => {
  const [durationData, setDurationData] = useState(dailyData);
  const [isMobile, setIsMobile] = useState(window.innerWidth);

  // Change Data
  useEffect(() => {
    switch (duration) {
      case "Yearly":
        setDurationData(yearlyData);
        break;
      case "Monthly":
        setDurationData(monthlyData);
        break;
      case "Daily":
        setDurationData(dailyData);
        break;
      default:
    }
  }, [duration]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    drawGraph();
  }, [durationData, zoomOut, isMobile]);

  // Draw Graph
  const svgRef = useRef();
  const drawGraph = () => {
    if (!durationData) return;

    // Setting up SVG
    const isMobile = window.innerWidth <= 414;
    const margin = { top: 90, right: 70, bottom: 72, left: 56 };
    const heightRatio = window.innerWidth / 1250;

    // const changeHeight = zoomOut ? 383 * heightRatio : 383;
    const changeHeight =
      isMobile && zoomOut
        ? 500
        : zoomOut
          ? 383 * heightRatio
          : window.innerWidth < 1300
            ? 383
            : 383;
    const changeWidth = zoomOut
      ? window.innerWidth
      : isMobile
        ? 414
        : window.innerWidth < 1300
          ? window.innerWidth
          : 1250;

    const svgHeight = changeHeight - margin.bottom - margin.top;
    const svgWidth = changeWidth - margin.left - margin.right;

    const initialYear = 2010;
    const endYear = new Date().getFullYear();
    let maxYValue = d3.max(durationData.flatMap((dataset) => dataset));

    const svg = d3
      .select(svgRef.current)
      .attr("width", changeWidth)
      .attr("height", changeHeight)
      .style("overflow", "visible")
      .style("margin-left", margin.left)
      .style("margin-top", zoomOut ? "180" : margin.top);
    svg.selectAll("*").remove();
    if (zoomOut) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    svg.selectAll(".line").remove();
    svg.selectAll(".x-axis-grid").remove();
    svg.selectAll(".y-axis-grid").remove();
    svg.select(".y-asis").remove();
    svg.select(".x-asis").remove();

    maxYValue = d3.max(durationData.flatMap((dataset) => dataset));

    // Setting up scailling
    const scaleX = d3
      .scaleLinear()
      .domain([initialYear, endYear - 2])
      .range([0, svgWidth]);

    const scaleY = d3
      .scaleLinear()
      .domain([0, maxYValue])
      .range([svgHeight, 0]);
    const generateScaledLine = d3
      .line()
      .x((d, i) => {
        return scaleX(initialYear + i);
      })
      .y(scaleY)
      .curve(d3.curveLinear);

    // Draw Axes
    const xAxis = d3
      .axisBottom(scaleX)
      .scale(scaleX)
      .ticks(durationData[0].length)
      .tickFormat((i) => i + 1);

    const yAxis = d3.axisLeft(scaleY).ticks(5);
    // Draw grid
    const xAxisGrid = d3
      .axisBottom(scaleX)

      .tickSize(svgHeight)
      .tickFormat("")
      .ticks(10);
    const yAxisGrid = d3
      .axisLeft(scaleY)
      .tickSize(-svgWidth)
      .tickFormat("")
      .ticks(5);

    // Setting data for SVG
    svg
      .append("g")
      .call(xAxis)
      .attr("class", "x-asis")
      .attr("transform", `translate(0, ${svgHeight})`);
    svg.append("g").call(xAxisGrid).attr("class", "x-axis-grid");
    svg.append("g").call(yAxis).attr("class", "y-asis");
    svg.append("g").call(yAxisGrid).attr("class", "y-axis-grid");

    // Setting axes
    durationData.forEach((dataset, index) => {
      svg
        .append("path")
        .datum(dataset)
        .attr("class", "line")
        .attr("d", generateScaledLine)
        .attr("fill", "none")
        .attr("stroke", d3.schemeCategory10[index]);
    });
  };
  useEffect(() => {
    drawGraph();
  }, [durationData, zoomOut, window.innerWidth]);

  return <svg ref={svgRef}></svg>;
};

export default LineGraphModel;
